export default class PageIntroBlock {
  constructor(container, options) {
      const defaultOptions = {
      }

      this.options = Object.assign({}, defaultOptions, options)
      this.container = document.querySelector(container)

      this.setEvents()
  }

  async setEvents() {
    this.animateElements1()
  }

  animateElements1 () {
    if (this.container !== undefined) {
      this.container.classList.add('animate1')

      setTimeout(() => {
        this.animateElements2()
      }, 500)
    }
  }

  animateElements2 () {
    if (this.container !== undefined) {
      this.container.classList.add('animate2')
    }
  }
}